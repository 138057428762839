import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Group} from './my-customers/types/group-type';
import {Email} from './my-customers/types/email-type';
import {Customer} from './my-customers/types/customer';
import {MasterReportRepresentation} from './master-report-representation';
import {BillingAddressRepresentation} from './billing-address-representation';
import {AccountService} from '../account/account.service';
import {catchError, timeout, map} from 'rxjs/operators';
import {MasterReport} from './master-report';
import {BillingAddress} from './billing-address';
import {CustomerRepresentation} from './my-customers/types/customer-representation';
import {NewClient} from '../client/new-client';
import {NewClientRepresentation} from '../client/new-client-representation';
import {TestInvitation} from './my-customers/test-invitation';
import {TestInvitationRepresentation} from './my-customers/test-invitation-representation';
import {GroupRepresentation} from './group-representation';
import {TestRepresentation} from '../test/state/test-representation';
import {Test} from '../test/state/test';
import {SimpleUserInvitation} from '../user/invitation/simple-user-invitation';
import {SimpleUserInvitationRepresentation} from '../user/invitation/simple-user-invitation-representation';

const API_URL = environment.API_URL;

interface PaginatedCustomersResponse {
  users: CustomerRepresentation[];
  totalElements: number;
}

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  private TIMEOUT_DURATION = 180000;

  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private totalNumberOfCustomers: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  private customers: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>([]);

  constructor(private http: HttpClient,
              private accountService: AccountService) {
  }

  public loadCustomers(): Observable<Customer[]> {
    return this.http.get<CustomerRepresentation[]>(
      API_URL + '/users',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(
      timeout(this.TIMEOUT_DURATION),
      map((customerRepresentations: CustomerRepresentation[]) => customerRepresentations
        .map(customerRepresentation => Customer.buildFrom(customerRepresentation))
      ));
  }

  public loadCustomersPaginated(page: number, size: number, searchValue: string = null, sortBy: string = null, sortDir: string = null){
    this.loading.next(true);
    let params = new HttpParams()
      .set('pageNumber', page.toString())
      .set('pageSize', size.toString())
      .set('sortBy', sortBy)
      .set('sortDir', sortDir);
    if (searchValue != null) {
      params = params.append('searchValue', searchValue);
    }
    this.http.get<PaginatedCustomersResponse>( API_URL + '/users',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        }),
        observe: 'response',
        params
      }
    ).subscribe(
      (response: HttpResponse<PaginatedCustomersResponse>) => {
        const loadedCustomers: Customer[] = response.body.users.map(customer => Customer.buildFrom(customer));
        this.customers.next(loadedCustomers);
        const totalCustomers = response.body.totalElements;
        this.totalNumberOfCustomers.next(totalCustomers);
        this.loading.next(false);
      }
    );
  }

  public inviteNewClients(newClients: NewClient[]): Observable<void> {
    return this.http.post<void>(
      API_URL + '/users',
      newClients.map(NewClientRepresentation.buildFrom),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1'
        })
      }
    );
  }

  public deleteCustomer(customer: Customer): Observable<boolean> {
    return this.accountService.deleteAccount(customer.keycloakId)
      .pipe(map(() => true), catchError(() => of(false)));
  }

  public loadGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(
      API_URL + '/groups',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public checkMailAddressTaken(email: Email): Observable<Customer | null> {
    return this.http.post<CustomerRepresentation | null>(
      API_URL + '/emails',
      email,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((customerRepresentation: CustomerRepresentation) =>
      customerRepresentation === null ? null : Customer.buildFrom(customerRepresentation)
    ));
  }

  public changeProduct(testInvitation: TestInvitation): Observable<any> {
    return this.http.patch(
      API_URL + '/users/tests/changeProduct',
      TestInvitationRepresentation.buildFrom(testInvitation),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public sendNewTestInvitation(testInvitation: TestInvitation): Observable<void> {
    return this.http.post<void>(
      API_URL + '/users/tests/invite',
      TestInvitationRepresentation.buildFrom(testInvitation),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public sendNewInvitation(simpleUserInvitation: SimpleUserInvitation): Observable<void> {
    return this.http.post<void>(
      API_URL + '/users/reInvite',
      SimpleUserInvitationRepresentation.buildFrom(simpleUserInvitation),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public grantTestAccess(email: Email, testId: string): Observable<Test> {
    return this.http.post<TestRepresentation>(
      API_URL + '/tests/' + testId + '/granted',
      email,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((testRepresentation: TestRepresentation) =>
      TestRepresentation.toModelEntity(testRepresentation)
    ));
  }

  public loadReport(): Observable<MasterReport> {
    return this.http.get<MasterReportRepresentation>(
      API_URL + '/master/report',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((masterReportRepresentation: MasterReportRepresentation) =>
      MasterReport.buildFrom(masterReportRepresentation)
    ));
  }

  public loadBillingAddress(): Observable<BillingAddress> {
    return this.http.get<BillingAddressRepresentation>(
      API_URL + '/master/billing-address',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((billingAddressRepresentation: BillingAddressRepresentation) =>
      BillingAddress.buildFrom(billingAddressRepresentation)
    ));
  }

  public saveBillingAddress(billingAddress: BillingAddress): Observable<void> {
    return this.http.post<void>(
      API_URL + '/master/billing-address',
      BillingAddressRepresentation.buildFrom(billingAddress),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public changeGroupOfCustomer(customer: Customer, groupRepresentation: GroupRepresentation): Observable<void> {
    return this.http.patch<void>(
      API_URL + '/groups/' + customer.keycloakId,
      groupRepresentation,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public changeGroupOfCustomersWithTheSameGroup(customer: Customer, groupRepresentation: GroupRepresentation): Observable<void> {
    return this.http.patch<void>(
      API_URL + '/groups/' + customer.keycloakId + '/forall',
      groupRepresentation,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  getTotalNumbersOfCustomers(){
    return this.totalNumberOfCustomers;
  }

  getCustomers(){
    return this.customers;
  }

  isLoading(){
    return this.loading;
  }

}
