import {map, timeout} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BasicUserRepresentation} from './basic-user-representation';
import {UserGroupRepresentation} from './user-group-representation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})

export class UserSearchService {

  private TIMEOUT_DURATION = 180000;

  constructor(private http: HttpClient) {
  }

  public searchUsers(searchValue: string = null, role: string = 'client', page: number = 1, size: number = 50) {
    let params = new HttpParams()
      .set('pageNumber', page.toString())
      .set('pageSize', size.toString())
      .set('role', role);
    if (searchValue != null) {
      params = params.append('searchValue', searchValue);
    }
    return this.http.get<{ users: BasicUserRepresentation[]; totalElements: number }>(
      API_URL + '/users/search',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        }),
        observe: 'response',
        params
      }
    ).pipe(
      timeout(this.TIMEOUT_DURATION),
      map(response =>
        response.body.users.map(basicUserRepresentation =>
          BasicUserRepresentation.toModelEntity(basicUserRepresentation)
        )
      )
    );
  }

  public searchUsersUsingNetworking(searchValue: string, page: number = 1, size: number = 50) {
    let params = new HttpParams()
      .set('pageNumber', page.toString())
      .set('pageSize', size.toString());
    if (searchValue != null) {
      params = params.append('searchValue', searchValue);
    }
    return this.http.get<{ users: BasicUserRepresentation[]; totalElements: number }>(
      API_URL + '/users/using-networking',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        }),
        observe: 'response',
        params
      }
    ).pipe(
      timeout(this.TIMEOUT_DURATION),
      map(response =>
        response.body.users.map(basicUserRepresentation =>
          BasicUserRepresentation.toModelEntity(basicUserRepresentation)
        )
      )
    );
  }

  public searchUsersByMasterOrOfPartner(role: string = 'master', keyCloakId: string, page: number = 1, size: number = 200) {
      const params = new HttpParams()
      .set('role', role)
      .set('referenceKeycloakId', keyCloakId)
      .set('pageNumber', page.toString())
      .set('pageSize', size.toString());
    return this.http.get<{ users: BasicUserRepresentation[]; totalElements: number }>( API_URL + '/users/searchByPartnerOrMaster',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        }),
        observe: 'response',
        params
      }
    ).pipe(
      timeout(this.TIMEOUT_DURATION),
      map(response =>
        response.body.users.map(basicUserRepresentation =>
          BasicUserRepresentation.toModelEntity(basicUserRepresentation)
        )
      )
    );
  }

  public searchUsersInFriendsAndGroups(searchValue: string, page: number = 1, size: number = 50){
    let params = new HttpParams()
      .set('pageNumber', page.toString())
      .set('pageSize', size.toString());
    if (searchValue != null) {
      params = params.append('searchValue', searchValue);
    }
    return this.http.get<{ users: UserGroupRepresentation[]; totalElements: number }>( API_URL + '/users/teams',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        }),
        observe: 'response',
        params
      }
    ).pipe(
      timeout(this.TIMEOUT_DURATION),
      map(response =>
        response.body.users.map(userGroupRepresentation =>
          UserGroupRepresentation.toModelEntity(userGroupRepresentation)
        )
      )
    );
  }
}
