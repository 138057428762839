import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClarityModule} from '@clr/angular';
import {ClrDatagridModule} from '@clr/angular';
import {MyCustomersComponent} from './my-customers/my-customers.component';
import {ReportModule} from '../report/report.module';
import {NewCustomersComponent} from './my-customers/new-customers/new-customers.component';
import {NewCustomersListComponent} from './my-customers/new-customers/new-customers-list/new-customers-list.component';
import {NewCustomersRowComponent} from './my-customers/new-customers/new-customers-list/new-customers-row/new-customers-row.component';
import {ToolboxModule} from './toolbox/toolbox.module';
import {SupportComponent} from './support/support.component';
import {DirectivesModule} from '../directives/directives.module';
import {SelectModule} from '../select/select.module';
import {Id37Module} from '../id37/id37.module';
import {ModalModule} from '../modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClarityModule,
    ReportModule,
    ToolboxModule,
    DirectivesModule,
    SelectModule,
    Id37Module,
    ModalModule,
    ClrDatagridModule
  ],
  declarations: [
    MyCustomersComponent,
    NewCustomersComponent,
    NewCustomersListComponent,
    NewCustomersRowComponent,
    SupportComponent
  ]
})
export class MasterModule {
}
