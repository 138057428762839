<div *ngIf="isOpen" [@openClose] class="customer-list-container">

  <div class="clr-row clr-justify-content-center">
    <div class="clr-col-12 invite-title">
      <span>{{__('addClients')}}</span>
    </div>
  </div>

  <app-new-customers-list #newCustomersListComponent
                          (validate)="checkValid()"
                          [customerFormArray]="newCustomerFormArray"
                          [groups$]="groups$"></app-new-customers-list>

  <div class="clr-row send-area">
    <div class="clr-col-6 clr-justify-content-start">
      <button (click)="addRow()" class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 id37-btn-hover-animation add-button">
        <img src="assets/icons/close_circle.svg" alt="plus symbol"/>
        <span>{{__('addClient')}}</span>
      </button>
    </div>
    <div class="clr-col-6 clr-justify-content-end">
      <button
          (click)="sendRows()"
          class="id37-btn-outline id37-btn-hover-animation send-button">
        <img src="assets/icons/send_invitation.svg" alt="letter symbol"/>
        <span>{{__('sendInvitations')}}</span>
      </button>
    </div>
  </div>

  @if(invalidCustomers){
    <div class="error-tooltip error-message">
      <div class="error-text">
        {{__('invalidData')}}
      </div>
      <img (click)="closeError()" id="error-message-close-button" src="assets/icons/x_white.svg">
    </div>
  }
</div>
