import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {BasicUser} from '../../basic-user';
import {CurrentUser} from '../../current-user.service';
import {UserSearchService} from '../../user-search.service';
import {TranslatorService} from '../../../translation/translator.service';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-user-search-input',
  templateUrl: './user-search-input.component.html',
  styleUrls: ['./user-search-input.component.scss']
})
export class UserSearchInputComponent implements OnInit, OnDestroy {

  @Input()
    additionalFilter: string;
  @Output()
    searching: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
    searchResult: EventEmitter<BasicUser[] | undefined> = new EventEmitter<BasicUser[] | undefined>();
  searchKey: string = '';
  searchInputChanged: Subject<string> = new Subject<string>();
  searchInputChangedSubscription: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private currentUser: CurrentUser,
              private userSearchService: UserSearchService,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.createSearchInputChangedSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.searchInputChangedSubscription.unsubscribe();
  }

  propagateSearchResult(searchResult: BasicUser[] | undefined): void {
    this.searchResult.emit(searchResult);
  }

  abortSearch(): void {
    this.searchKey = '';
    this.propagateSearchResult(undefined);
    this.searching.emit(false);
    this.createSearchInputChangedSubscription();
  }

  __(key: string): string {
    return this.translatorService.translate('friendSearch.' + key);
  }

  private createSearchInputChangedSubscription(): void {
    if (this.searchInputChangedSubscription) {
      this.searchInputChangedSubscription.unsubscribe();
    }
    this.searchInputChangedSubscription = this.searchInputChanged
      .pipe(
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe(newSearchKey => {
        this.searchKey = newSearchKey;
        this.search();
      });
  }

  private search(): void {
    const toSearch: string = this.processedSearchKey();
    if (this.searchKey.length >= 3 && toSearch.length > 3) {
      this.propagateSearchResult(undefined);
      this.searching.emit(true);
      this.userSearchService.searchUsers(toSearch, this.additionalFilter)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((list: BasicUser[]) => {
          this.propagateSearchResult(list);
        });
    }
    if (this.searchKey.length === 0) {
      this.abortSearch();
    }
  }

  private processedSearchKey(): string {
    return this.searchKey.trim()
      .split(/\s/g)
      .filter(word => word.length > 1)
      .filter((word, index) => index < 5)
      .join('~');
  }

}
