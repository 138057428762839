import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MasterService} from '../../master.service';
import {Group} from '../types/group-type';
import {Customer} from '../types/customer';
import {UserDataValidation} from '../../../user-data/user-data-validation';
import {TranslatorService} from '../../../translation/translator.service';
import {TestVariation} from '../../../test/state/test-variation';
import {MasterUserInvitationRepresentation} from '../../../user/invitation/master-user-invitation-representation';
import {UserInvitationService} from '../../../user/invitation/user-invitation.service';
import {NewCustomersListComponent} from './new-customers-list/new-customers-list.component';

@Component({
  selector: 'app-new-customers',
  templateUrl: './new-customers.component.html',
  styleUrls: ['./new-customers.component.scss'],
  animations: [
    trigger('openClose', [
      state('*', style({
        height: '*',
        opacity: 1
      })),
      state('void', style({
        height: 0,
        opacity: 0
      })),
      transition('* => void', [
        animate('300ms ease-out')
      ]),
      transition('void => *', [
        animate('300ms ease-in')
      ])
    ])
  ]
})
export class NewCustomersComponent implements OnInit {

  @Input()
    groups$: Observable<Group[]>;

  @Input()
    isOpen: boolean;

  @Output()
    sent: EventEmitter<Observable<void>> = new EventEmitter<Observable<void>>();

  @ViewChild('newCustomersListComponent') newCustomersListComponent: NewCustomersListComponent;

  newCustomerArray: Customer[];
  newCustomerFormArray: FormGroup[];
  newCustomerFormArrayValid = false;
  invalidCustomers: boolean = false;

  constructor(private masterService: MasterService,
              private userInvitationService: UserInvitationService,
              private formBuilder: FormBuilder,
              private translatorService: TranslatorService) {
  }

  ngOnInit() {
    this.createFormArray();
  }

  createFormArray() {
    this.newCustomerFormArray = [];
    this.addRow();
  }

  buildFormGroup(): FormGroup {
    return this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required, Validators.maxLength(UserDataValidation.EMAIL_MAX_LENGTH), Validators.email
      ])],
      surname: ['', Validators.compose([
        Validators.required, Validators.maxLength(UserDataValidation.NAME_MAX_LENGTH)
      ])],
      firstName: ['', Validators.compose([
        Validators.required, Validators.maxLength(UserDataValidation.NAME_MAX_LENGTH)
      ])],
      group: this.newCustomerFormArray.length !== 0
        ? this.newCustomerFormArray[this.newCustomerFormArray.length - 1].getRawValue().group
        : '',
      variation: [this.newCustomerFormArray.length !== 0
        ? this.newCustomerFormArray[this.newCustomerFormArray.length - 1].getRawValue().variation
        : '', Validators.required]
    });
  }

  addRow() {
    this.newCustomerFormArray.push(this.buildFormGroup());
    this.newCustomerFormArrayValid = false;
  }

  checkValid() {
    let allValid = true;

    // Check duplicated emails.
    this.newCustomerFormArray.forEach(formGroupA => {
      this.newCustomerFormArray.forEach(formGroupB => {
        if (formGroupA !== formGroupB) {
          if (formGroupA.controls.email.getError('duplicate') === true &&
            formGroupB.controls.email.getError('duplicate') !== true) {
            formGroupA.controls.email.setErrors({duplicate: false});
          }
          if (formGroupA.controls.email.value === formGroupB.controls.email.value) {
            formGroupA.controls.email.setErrors({duplicate: true});
            formGroupB.controls.email.setErrors({duplicate: true});
            allValid = false;
          }
        }
      });
    });

    // Check general validity.
    this.newCustomerFormArray.forEach(formGroup => {
      if (!formGroup.valid) {
        allValid = false;
      }
    });

    this.newCustomerFormArrayValid = allValid;
  }

  sendRows() {
    this.newCustomersListComponent.triggerRowValidation();

    if (!this.newCustomerFormArrayValid) {
      console.error('The form is not valid. Rows were not send.');
      this.invalidCustomers = true;
    }
    else {
      this.sent.emit(this.userInvitationService.inviteUsersByMasterOrPartner(
        this.newCustomerFormArray.map(formGroup => {
          const variation = TestVariation.fromName(formGroup.controls.variation.value as string);
          return new MasterUserInvitationRepresentation(
            formGroup.controls.email.value as string,
            formGroup.controls.firstName.value as string,
            formGroup.controls.surname.value as string,
            formGroup.controls.group.value as string,
            !!variation ? variation.internalName : undefined
          );
        })
      ));
    }

  }

  closeError(): void {
    this.invalidCustomers = false;
  }

  __(key: string): string {
    return this.translatorService.translate('master.my-customers.' + key);
  }

}
