<div *ngIf="apiIsHealthy === null">
  <app-centered-loading-indicator></app-centered-loading-indicator>
</div>

<div *ngIf="apiIsHealthy === true">
  <!-- Container -->
  @if (canShowChatbotJay()) {
    <div [ngClass]="{'chatbot-chat-selected': !!chatObject}" class="chatbot-container">


      @if (isMobile){
        <!-- Mobile Bar -->
      <div class="chatbot-mobile-sidebar-pane show-on-mobile">
        <app-chatbot-chat-mobile-bar (chatSelected)="onChatSelected($event)"
                                     [conversationName]="chatObject?.title ?? 'Chatbot Jay'"
                                     (sidebarEnabled)="toggleSidebar()"></app-chatbot-chat-mobile-bar>
      </div>
      } @else {
        <!-- Sidebar -->
        <div class="chatbot-sidebar-pane">
          <app-chatbot-chat-selections [selectedChat]="selectedChat"
                                       (chatSelected)="onChatSelected($event)"></app-chatbot-chat-selections>
        </div>
      }

      <!-- Messages & Input-Footer -->
      <div class="chatbot-content-pane">
        <ng-container *ngIf="!!chatObject; else startScreen">
          <app-chatbot-chat-messages [chatbotChat]="chatObject" [mobileSidebar]="showMobileSidebar">
          </app-chatbot-chat-messages>
        </ng-container>
        <ng-container>
          <app-chatbot-chat-input [chatbotChat]="chatObject"
                                  [errorMessage]="errorMessage"
                                  [errorOccurred]="errorOccurred"
                                  (sendingMessage)="messageSend()"
                                  (chatCreated)="addChatToChatList($event)" #chatInput></app-chatbot-chat-input>
        </ng-container>
        <ng-template #startScreen>
          <app-chatbot-startscreen (startSample)="chatInput.newChatSample($event)"></app-chatbot-startscreen>
        </ng-template>
      </div>
    </div>
  } @else if (currentUsersChatbotStatus === ChatbotUserStatus.chatbot_error) {
    <app-chatbot-error-screen></app-chatbot-error-screen>
  } @else {
    <app-chatbot-locked-screen
      [translatorKey]="translatorKeyLockedComponent"
      (cancelSubscriptionEvent)="handleCancelSubscription()"
      (subscribeToChatbotJayEvent)="handleSubscribeToJay()">
    </app-chatbot-locked-screen>
  }
</div>

<div *ngIf="apiIsHealthy === false">
  <app-service-unavailable></app-service-unavailable>
</div>

<!-- Mobile Sidebar -->
<ng-template [ngIf]="showMobileSidebar">
  <app-chatbot-chat-mobile-sidebar [selectedChat]="selectedChat" (chatSelected)="onChatSelected($event)"
                                   (closeSidebar)="toggleSidebar()"></app-chatbot-chat-mobile-sidebar>
</ng-template>
