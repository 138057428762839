import {Component, OnInit} from '@angular/core';
import {MessageService} from '../message.service';
import {UserTargetFilter} from '../state/user-target-filter';
import {MessagePublicationMethod} from '../state/message-publication-method';
import {Id37Language} from '../../../shared/id37-language';
import {Message} from '../state/message';
import {take} from 'rxjs/operators';
import {MulticastMessage} from '../state/multicast-message';
import {MessagePreview} from '../state/message-preview';
import {BasicUserInvitation} from '../../../user/invitation/basic-user-invitation';
import {BasicUser} from '../../../user/basic-user';
import {UserSearchService} from '../../../user/user-search.service';
import {Choice} from '../../../select/state/choices-types';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  userTargetFilters = UserTargetFilter;
  messagePublicationMethods = MessagePublicationMethod;
  languages = Id37Language;

  userTargetFilter: UserTargetFilter | undefined = undefined;
  userTargetFilterData: string[] = [];
  messagePublicationMethod: MessagePublicationMethod | undefined = undefined;

  messages: { [key: string]: Message } = {};

  previewLanguage: Id37Language = Id37Language.en;
  messagePreview: MessagePreview | undefined = undefined;
  loadingMessagePreview: boolean = false;
  loadingMessagePreviewFailed: boolean = false;
  isAdvertising: boolean = false;

  receivers: BasicUserInvitation[] | undefined = undefined;
  currentReceivers: [string[], string[]] = [[], []];

  loadingReceivers: boolean = false;
  loadingReceiversFailed: boolean = false;

  readyToSend: [boolean, string[]] = [false, []];

  success: boolean = false;
  error: boolean = false;
  notSendTo: string[];

  formats: string[];
  modules: unknown;

  searchUsers = false;
  searchUsersFromPartner = false;
  searchUsersFromMaster = false;
  searchMasters = false;
  searchPartners = false;

  additionalFilter: string;

  searchFilterKey: string = '';
  foundUsers: BasicUser[];
  userOfMasterOrPartner: BasicUser[];
  choices: Choice[] = [];
  choicesForReceivers: Choice[] = [];
  keycloakOfMasterOrPartnerOfSearchedUsers: string;
  planedReceivers: BasicUser[] = [];

  constructor(private messageService: MessageService, private userSearchService: UserSearchService) {
    for (const language of Id37Language.STRING_LIST) {
      this.messages[language] = {title: '', text: ''};
    }
    this.updateReadyState();
  }

  ngOnInit(): void {
    this.formats = [
      'bold', 'italic', 'underline', 'strike',
      'align', 'header', 'list', 'indent', 'size', 'color',
      'link',
      'clean'
    ];
    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{align: []}],
        // ['blockquote', 'code-block'],
        [{header: 1}, {header: 2}],                       // custom button values
        [{list: 'ordered'}, {list: 'bullet'}],
        // [{script: 'sub'}, {script: 'super'}],          // superscript/subscript
        [{indent: '-1'}, {indent: '+1'}],                 // outdent / indent
        // [{direction: 'rtl'}],                          // text direction
        [{size: ['small', false, 'large', 'huge']}],      // custom dropdown
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        [{color: []} /* , {background: []}*/],            // dropdown with defaults from theme
        // [{font: []}],
        ['link'],
        ['clean']                                         // remove formatting button
      ]
    };
  }

  setUserTargetFilter(userTargetFilter: UserTargetFilter): void {
    this.abortSearch();
    this.userTargetFilter = userTargetFilter;
    this.resetReceivers();
    this.updateReadyState();
    this.searchUsers = userTargetFilter === UserTargetFilter.CHOSEN_FILTERED_USERS;
    this.searchUsersFromMaster = userTargetFilter === UserTargetFilter.CHOOSE_MASTER_CLIENTS;
    this.searchUsersFromPartner = userTargetFilter === UserTargetFilter.CHOOSE_PARTNER_CLIENTS;
    this.searchMasters = userTargetFilter === UserTargetFilter.CHOOSE_MASTERS;
    this.searchPartners = userTargetFilter === UserTargetFilter.CHOOSE_PARTNERS;

    switch (true) {
      case this.searchUsersFromMaster:
        this.additionalFilter = 'master';
        break;
      case this.searchUsersFromPartner:
        this.additionalFilter = 'partner';
        break;
      case this.searchMasters:
        this.additionalFilter = 'master';
        break;
      case this.searchPartners:
        this.additionalFilter = 'partner';
        break;
      default:
        this.additionalFilter = 'client';
    }
    if (!this.searchUsers) {
      this.userTargetFilterData = [];
      this.currentReceivers = [[], []];
    }
  }

  setMessagePublicationMethod(messagePublicationMethod: MessagePublicationMethod): void {
    this.messagePublicationMethod = messagePublicationMethod;
    this.updateReadyState();
  }

  setTitle(language: Id37Language, title: string): void {
    this.messages[language].title = title.trim();
    this.updateReadyState();
  }

  setText(language: Id37Language, text: string): void {
    this.messages[language].text = text.trim();
    this.updateReadyState();
  }

  updateReadyState(): void {
    this.readyToSend = this.checkReadyToSend();
  }

  addUserForMailing(user: BasicUser) {
    if (!this.userTargetFilterData.includes(user.keycloakId) &&
      !this.planedReceivers.includes(user)) {
      this.userTargetFilterData.push(user.keycloakId);
      this.currentReceivers.push([user.firstName, user.lastName]);
      this.planedReceivers.push(user);
    }
  }

  checkReadyToSend(): [boolean, string[]] {
    if (typeof this.userTargetFilter === typeof undefined) {
      return [false, ['Please choose a target filter.']];
    }
    if (typeof this.messagePublicationMethod === typeof undefined) {
      return [false, ['Please choose a publication method.']];
    }
    // Checking texts.
    if (this.messages[Id37Language.en].title === '') {
      return [false, ['Please enter an english title.']];
    }
    if (this.messages[Id37Language.en].text === '') {
      return [false, ['Please enter an english text.']];
    }
    // Each message for which a title is given also needs a text.
    for (const [language, message] of Object.entries(this.messages)) {
      if (message.title !== '' && message.text === '') {
        return [false, ['You are missing a text for language "' + language + '".']];
      }
    }
    // Each message for which a text is given also needs a title.
    for (const [language, message] of Object.entries(this.messages)) {
      if (message.text !== '' && message.title === '') {
        return [false, ['You are missing a title for language "' + language + '".']];
      }
    }
    return [true, []];
  }

  buildMulticastMessage(): MulticastMessage {
    const map = new Map<Id37Language, Message>();
    for (const [language, message] of Object.entries(this.messages)) {
      if (message.title !== '' && message.text !== '') {
        map.set(Id37Language.fromName(language), message);
      }
    }
    let userTargetFilterToSend = this.userTargetFilter;
    if (this.userTargetFilter === UserTargetFilter.CHOOSE_MASTERS
      || this.userTargetFilter === UserTargetFilter.CHOOSE_PARTNERS
      || this.userTargetFilter === UserTargetFilter.CHOOSE_PARTNER_CLIENTS
      || this.userTargetFilter === UserTargetFilter.CHOOSE_MASTER_CLIENTS) {
      userTargetFilterToSend = UserTargetFilter.CHOSEN_FILTERED_USERS;
    }
    return {
      localizedMessage: {
        localizedMessage: map
      },
      messagePublicationMethod: this.messagePublicationMethod,
      userTargetFilter: userTargetFilterToSend,
      userTargetFilterData: this.userTargetFilterData,
      gettingAdvertising: this.isAdvertising
    };
  }

  getMessagePreview(): void {
    this.resetMessagePreview();
    this.loadingMessagePreview = true;
    this.messageService.multicastMessagePreview(this.buildMulticastMessage(), this.previewLanguage).pipe(take(1))
      .subscribe((messagePreview) => {
        this.messagePreview = messagePreview;
        this.loadingMessagePreview = false;
      }, () => {
        this.loadingMessagePreview = false;
        this.loadingMessagePreviewFailed = true;
      }
      );
  }

  resetMessagePreview(): void {
    this.messagePreview = undefined;
    this.loadingMessagePreview = false;
    this.loadingMessagePreviewFailed = false;
  }

  getReceiversPreview(): void {
    this.resetReceivers();
    this.loadingReceivers = true;
    this.messageService.multicastMessageReceiversPreview(this.buildMulticastMessage()).pipe(take(1))
      .subscribe(receivers => {
        this.receivers = receivers;
        this.loadingReceivers = false;
      }, () => {
        this.loadingReceivers = false;
        this.loadingReceiversFailed = true;
      }
      );
  }

  resetReceivers(): void {
    this.receivers = undefined;
    this.loadingReceivers = false;
    this.loadingReceiversFailed = false;
  }

  abortSearch() {
    this.foundUsers = undefined;
    this.searchFilterKey = '';
    this.choices = [];
    this.planedReceivers = [];
    this.userOfMasterOrPartner = [];
    this.choicesForReceivers = [];
  }

  searchInputFilterChanged(searchText: string, targetFilter: string) {
    if (searchText.length >= 3) {
      this.userSearchService.searchUsers(searchText, targetFilter)
        .subscribe((list: BasicUser[]) => {
          this.foundUsers = list;
        });
    }
    this.choices = [];
    if (!!this.foundUsers) {
      for (const foundUser of this.foundUsers) {
        this.choices.push({value: foundUser.keycloakId, label: foundUser.firstName + ' ' + foundUser.lastName});
      }
    }
    if (searchText === '') {
      this.abortSearch();
    }
  }

  selectionOfMasterOrPartner(selected: Choice | Choice[] | undefined): void {
    if (!selected || Array.isArray(selected)) {
      console.error('Unable to handle array-like selection.');
      return;
    }
    // Search selected user.
    this.keycloakOfMasterOrPartnerOfSearchedUsers = selected.value;
    this.userSearchService.searchUsersByMasterOrOfPartner(this.additionalFilter, this.keycloakOfMasterOrPartnerOfSearchedUsers)
      .subscribe((list: BasicUser[]) => {
        for (const foundUser of list) {
          this.choicesForReceivers.push({
            value: foundUser.keycloakId,
            label: foundUser.firstName + ' ' + foundUser.lastName
          });
        }
        this.choicesForReceivers.push({
          value: 'ALL',
          label: 'All users'
        });
        this.userOfMasterOrPartner = list;
      });
  }

  selectionOfUsersOfMasterOrPartner(selected: Choice): void {
    if (!selected || Array.isArray(selected)) {
      console.error('Unable to handle array-like selection.');
      return;
    }

    for (const planedReceiver of this.userOfMasterOrPartner) {
      if (selected.value === 'ALL') {
        if (!this.planedReceivers.includes(planedReceiver) &&
          !this.userTargetFilterData.includes(planedReceiver.keycloakId)) {
          this.planedReceivers.push(planedReceiver);
          this.userTargetFilterData.push(planedReceiver.keycloakId);
        }
      }
      else if (!this.planedReceivers.includes(planedReceiver) &&
        !this.userTargetFilterData.includes(selected.value) &&
        planedReceiver.keycloakId === selected.value) {
        this.planedReceivers.push(planedReceiver);
        this.userTargetFilterData.push(selected.value);
      }
    }

  }

  deleteReceiver(receiver: BasicUser) {
    const indexOfReceiver = this.planedReceivers.indexOf(receiver);
    const indexOfKeycloakId = this.userTargetFilterData.indexOf(receiver.keycloakId);
    this.planedReceivers.splice(indexOfReceiver, 1);
    this.userTargetFilterData.splice(indexOfKeycloakId, 1);
  }

  send(): void {
    this.messageService.multicastMessage(this.buildMulticastMessage()).pipe(take(1))
      .subscribe(
        (notSendTo) => {
          this.success = true;
          setTimeout(() => this.success = false, 4000);
          this.notSendTo = notSendTo;
        },
        () => {
          this.error = true;
          setTimeout(() => this.error = false, 4000);
        });
  }
}
