import {ChatbotChat, ChatbotChatCreate} from '../state/chatbot-chat';
import {CurrentUser} from '../../user/current-user.service';
import {ChatbotChatService} from '../api-services/chatbot-chat.service';
import {ChatbotErrorService} from './chatbot-error.service';
import {Injectable} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';


@Injectable({
  providedIn: 'root'
})
export class ChatbotChatCreateService{

  constructor(private currentUser: CurrentUser,
              private chatbotChatService: ChatbotChatService,
              private chatbotErrorService: ChatbotErrorService,
              private translatorService: TranslatorService) {
  }
  async createChat(): Promise<ChatbotChat> {
    const chatCreate = new ChatbotChatCreate(this.currentUser.keycloakId);
    const chatPromise = this.chatbotChatService.addChat(chatCreate).toPromise();
    let chat: ChatbotChat;

    try {
      await chatPromise.then((data) => {
        chat = data;
      });
    }
    catch (error) {
      // Ensures that an error message is shown when sending a message without chatbot connection
      // even when starting a new chat
      this.chatbotErrorService.setErrorOccurred(true);
      this.chatbotErrorService.setErrorMessage(
        this.translatorService.translate('chatbot-send-message.errorMessage')
      );
      console.error('Error', error);

      setTimeout(() => {
        this.chatbotErrorService.setErrorOccurred(false);
      }, 10000);
    }

    return  chat;
  }
}
