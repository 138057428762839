import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Group} from '../../../types/group-type';
import {Customer} from '../../../types/customer';
import {MasterService} from '../../../../master.service';
import {TranslatorService} from '../../../../../translation/translator.service';
import {Email} from '../../../types/email-type';
import {ChoicesOptions} from '../../../../../user-data/choices-options';
import {Choice} from '../../../../../select/state/choices-types';
import {SelectComponent} from '../../../../../select/select/select.component';

@Component({
  selector: 'app-new-customers-row',
  templateUrl: './new-customers-row.component.html',
  styleUrls: ['./new-customers-row.component.scss']
})
export class NewCustomersRowComponent implements OnDestroy {

  @Input()
    customerForm: FormGroup;
  @Input()
    groups$: Observable<Group[]>;
  @Output()
    deleted = new EventEmitter();
  @Output()
    validate = new EventEmitter();
  @ViewChild('testVariationSelection') testVariationSelection: SelectComponent;
  readonly variationChoices: Choice[];
  customerTaken = false;
  duplicateMail = false;
  incorrectMail = false;
  private unsubscribe$ = new Subject<void>();

  constructor(private masterService: MasterService,
              private translatorService: TranslatorService) {
    this.variationChoices = ChoicesOptions.variations();
    this.variationChoices.push({value: 'customer-chooses', label: this.___('test.variation.unspecified')});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  deleteRow() {
    this.customerForm.reset();
    this.deleted.emit();
    this.clearErrors();
  }

  clearErrors(): void {
    this.duplicateMail = false;
    this.incorrectMail = false;
    this.customerTaken = false;
  }

  checkMailAddressValidity(): void {
    if (this.customerForm.controls.email.value) {
      this.masterService.checkMailAddressTaken(new Email(this.customerForm.controls.email.value as string))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
          if (!!data && data.email) {
            this.customerTaken = true;
          }
          else {
            this.customerTaken = false;
          }
        });
      this.checkValid();
    }
  }

  onVariationSelection(selected: Choice | Choice[] | undefined): void {
    if (!!selected && !Array.isArray(selected)) {
      this.customerForm.controls.variation.setValue(selected.value);
    }
  }

  checkValid(): void {
    this.validate.emit();

    // Update the error sates:
    const emailFormControl: AbstractControl = this.customerForm.controls.email;
    this.duplicateMail = emailFormControl.hasError('duplicate')
      && emailFormControl.getError('duplicate') === true;
    this.incorrectMail = emailFormControl.hasError('email')
      && emailFormControl.getError('email') === true;
  }

  __(key: string): string {
    return this.translatorService.translate('master.my-customers.' + key);
  }

  ___(key: string): string {
    return this.translatorService.translate(key);
  }

}
