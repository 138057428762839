import {Component, ViewChild} from '@angular/core';
import {MasterService} from '../master.service';
import {TranslatorService} from '../../translation/translator.service';
import {CurrentUser} from '../../user/current-user.service';
import {Router} from '@angular/router';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Customer, myCustomersAction} from './types/customer';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GroupRepresentation} from '../group-representation';
import {Email} from './types/email-type';
import {TestVariation} from '../../test/state/test-variation';
import {TestInvitation} from './test-invitation';
import {Observable} from 'rxjs';
import {NewCustomersComponent} from './new-customers/new-customers.component';
import {Group} from './types/group-type';

@Component({
  selector: 'app-my-customers',
  templateUrl: './my-customers.component.html',
  styleUrls: ['./my-customers.component.scss']
})

export class MyCustomersComponent {

  loading: boolean = true;
  customers: Customer[] = [];
  totalCustomerCount: number = 0;
  latestDatagridState: ClrDatagridStateInterface | null = null;

  // Customer deletion
  customerToDelete: Customer;
  showDeleteModal: boolean = false;

  // Group change
  customerToChangeGroup: Customer;
  newGroup = new FormControl('');
  groupChangeForAll: boolean = false;
  showGroupChangeModal: boolean = false;

  // Test variation change
  variationChangeSelectedCustomer: Customer;
  showTestVariationModal: boolean = false;
  testVariationChecked: any;
  form = new FormGroup({
    testVariation: new FormControl('', Validators.required)
  });
  variationString: string;

  // New customers component
  @ViewChild(NewCustomersComponent, {static: true})
  newCustomersComponent: NewCustomersComponent;
  showNewCustomers: boolean = false;
  groups$: Observable<Group[]>;

  // Search function
  searchInput: string = '';

  constructor(private masterService: MasterService,
              private translatorService: TranslatorService,
              private currentUser: CurrentUser,
              private router: Router) {
    this.initializeCustomers();
  }

  refresh(state: ClrDatagridStateInterface): void {

    const pageNumber = state.page?.size || 10;
    const currentPage = state.page?.current || 1;
    let sortBy = typeof state.sort?.by === 'string' ? state.sort.by : null;
    if (sortBy === 'name') {
      sortBy = null;
    }
    const sortDir = state.sort?.reverse ? 'desc' : 'asc';
    const searchValue = this.searchInput.length >= 3 ? this.searchInput : null;

    this.masterService.loadCustomersPaginated(currentPage, pageNumber, searchValue, sortBy, sortDir);

    this.latestDatagridState = state;
  }

  initializeCustomers(): void {
    this.masterService.getCustomers().subscribe(customers => {
      this.customers = customers;
    });

    this.masterService.isLoading().subscribe(isLoading => {
      this.loading = isLoading;
    });

    this.masterService.getTotalNumbersOfCustomers().subscribe(totalCustomerNumber => {
      this.totalCustomerCount = totalCustomerNumber;
    });
  }


  /*
  View profile functions
   */
  openReport(userId: string, testId: string): void {
    this.router.navigate(['report', userId, testId]);
  }


  /*
  Group change functions
   */
  openGroupChangeModal(customer: Customer): void {
    this.customerToChangeGroup = customer;
    this.newGroup.setValue(customer.groupName);
    this.groupChangeForAll = false;
    this.showGroupChangeModal = true;
  }

  closeGroupChangeModal(): void {
    this.showGroupChangeModal = false;
  }

  changeGroupOfCustomer(customer: Customer): void {
    if (this.groupChangeForAll) {
      this.masterService.changeGroupOfCustomersWithTheSameGroup(
        customer,
        GroupRepresentation.buildFrom(this.newGroup.value, this.currentUser.keycloakId))
          .subscribe(() => this.refresh(this.latestDatagridState));
    }
    else {
      this.masterService.changeGroupOfCustomer(
        customer,
        GroupRepresentation.buildFrom(this.newGroup.value, this.currentUser.keycloakId))
          .subscribe(() => this.refresh(this.latestDatagridState));
    }
  }


  /*
  Delete customer functions
   */
  openDeleteModal(customer: Customer): void {
    this.customerToDelete = customer;
    this.showDeleteModal = true;
  }

  closeDeleteModal(): void {
    this.showDeleteModal = false;
  }

  deleteCustomer(customer: Customer): void {
    this.masterService.deleteCustomer(customer)
      .subscribe(() => this.refresh(this.latestDatagridState));
  }


  /*
  Customer action functions
   */
  sendNewInvite(customer: Customer): void {
    this.masterService.sendNewInvitation({email: customer.email})
      .subscribe(() => this.refresh(this.latestDatagridState));
  }

  grantTestAccess(customer: Customer): void {
    this.masterService.grantTestAccess(new Email(customer.email), customer.getFirstTest().testId)
      .subscribe(() => this.refresh(this.latestDatagridState));
  }

  sendTestInvite(customer: Customer): void {
    if (this.variationString) {
      const testVariation = this.variationString === 'customer-chooses'
        ? undefined : TestVariation.fromName(this.variationString);
      this.masterService.sendNewTestInvitation(new TestInvitation(customer.email, testVariation))
        .subscribe(() => this.refresh(this.latestDatagridState));
    }
  }

  changeTestVariation(customer: Customer): void {
    if (this.variationString) {
      const testVariation = this.variationString === 'customer-chooses'
        ? undefined : TestVariation.fromName(this.variationString);
      this.masterService.changeProduct(new TestInvitation(customer.email, testVariation))
        .subscribe(() => this.refresh(this.latestDatagridState));
    }
  }

  openVariationModal(customer: Customer): void {
    this.variationChangeSelectedCustomer = customer;
    this.showTestVariationModal = true;
    this.testVariationChecked = customer.getVariationOfFirstTest();
  }

  changeVariation(event: any): void {
    this.variationString = event.target.value;
  }

  onVariationSelection(): void {
    if (this.variationChangeSelectedCustomer.hasNoTests()) {
      this.sendTestInvite(this.variationChangeSelectedCustomer);
    }
    else {
      this.changeTestVariation(this.variationChangeSelectedCustomer);
    }
    this.showTestVariationModal = false;
  }


  // New customers component
  toggleNewCustomers(): void {
    this.showNewCustomers = !this.showNewCustomers;
  }

  onSent(post: Observable<void>): void {
    this.toggleNewCustomers();
    post.pipe().subscribe(
      () => {
        this.newCustomersComponent.createFormArray();
        this.refresh(this.latestDatagridState);
      }
    );
  }


  // Search functions
  searchCustomers(): void {
    this.refresh(this.latestDatagridState);
  }

  clearSearchInput(): void {
    if (this.searchInput.length >= 3) {
      this.searchInput = '';
      this.searchCustomers();
    }
    else {
      this.searchInput = '';
    }
  }

  __(key: string): string {
    return this.translatorService.translate('master.my-customers.' + key);
  }

  protected readonly myCustomersAction = myCustomersAction;
}
