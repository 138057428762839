<!--suppress HtmlFormInputWithoutLabel -->
<form [formGroup]="customerForm" class="clr-row customer-row ">

  <div class="clr-col-3 cell">
    <input [formControl]="customerForm.controls['email']"
           [ngClass]="{'error': (duplicateMail || incorrectMail || customerTaken)}"
           name="email"
           placeholder="{{__('email')}}"
           type="text">
  </div>

  <div class="clr-col-2 cell">
    <input [formControl]="customerForm.controls['surname']" name="surname"
           placeholder="{{__('lastName')}}"
           type="text">
  </div>

  <div class="clr-col-2 cell">
    <input [formControl]="customerForm.controls['firstName']" name="name"
           placeholder="{{__('firstName')}}"
           type="text">
  </div>

  <div class="clr-col-2 cell">
    <input [formControl]="customerForm.controls['group']" list="groups" name="group"
           placeholder="{{__('group')}}"
           type="text">

    <datalist class="group" id="groups">
      <option *ngFor="let group of groups$ | async" [ngValue]="group">
        {{ group.name }}
      </option>
    </datalist>
  </div>

  <div class="clr-col-2 cell">
    <app-select
      (selection)="onVariationSelection($event)"
      [choices]="variationChoices"
    ></app-select>
  </div>

  <div class="clr-col-1 cell abort-cell">
    <button (click)="deleteRow()" class="id37-btn id37-btn-icon abort-btn" type="button">
      <img alt="delete row" src="assets/icons/ID37_X.svg"/>
    </button>
  </div>
</form>

<div *ngIf="customerTaken" class="clr-row error-tooltip">
  <div class="clr-col-12">
    <div>{{__('emailAlreadyInUse')}}</div>
  </div>
</div>

<div *ngIf="incorrectMail" class="clr-row error-tooltip">
  <div class="clr-col-12">
    <div>{{__('notAValidEmail')}}</div>
  </div>
</div>

<div *ngIf="duplicateMail" class="clr-row error-tooltip">
  <div class="clr-col-12">
    <div>{{__('emailMustNotBeUsedMultipleTimes')}}</div>
  </div>
</div>
