import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {ChatbotChat} from '../state/chatbot-chat';
import {ChatbotChatSelectionsComponent} from '../chatbot-chat-selections/chatbot-chat-selections.component';
import {ChatbotHealthService} from '../api-services/chatbot-health.service';
import {ChatbotErrorService} from '../utility-services/chatbot-error.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ChatbotUserService} from '../api-services/chatbot-user.service';
import {ChatbotUserStatus} from '../../user-data/chatbot-user-status';
import {UserService} from '../../user/user.service';
import {ChatbotUserCreateService} from '../utility-services/chatbot-user-create.service';
import {KeycloakService} from 'keycloak-angular';
import {ChatbotChatEventService} from '../utility-services/chatbot-chat-event.service';
import {filter} from 'rxjs/operators';
import {CartUpdate} from '../../order/cart/cart-update';
import {Product} from '../../product/product';
import {CartService} from '../../order/cart/cart-service';
import {Router} from '@angular/router';
import {TranslatorService} from '../../translation/translator.service';
import {interval} from 'rxjs';

const chatBreakPoint = 900;
export default chatBreakPoint;
const FREE_TRIAL_TRANSLATOR_KEY = 'free-trial';
const FREE_TRIAL_EXPIRED_TRANSLATOR_KEY = 'free-trial-expired';

@UntilDestroy()
@Component({
  selector: 'app-chatbot-chat',
  templateUrl: './chatbot-chat.component.html',
  styleUrls: ['./chatbot-chat.component.scss']
})
export class ChatbotChatComponent implements OnInit, OnDestroy {

  @ViewChild(ChatbotChatSelectionsComponent) chatbotChatSelectionsComponent: ChatbotChatSelectionsComponent;

  apiIsHealthy: boolean = true;
  errorOccurred: boolean = false;
  errorMessage: string = '';
  isMobile: boolean = false;
  showStartFreeTrialLockScreen: boolean = false;
  showPurchaseAccessLockScreen: boolean = false;

  navigationReason: 'IS_PURCHASE' | 'IS_FREE_TRIAL' | 'CANCEL' = 'CANCEL';
  translatorKeyLockedComponent: string;

  chatObject: ChatbotChat | undefined = undefined;
  showMobileSidebar = false;

  selectedChat: ChatbotChat;
  currentUsersChatbotStatus: ChatbotUserStatus;

  constructor(
    private chatbotHealthService: ChatbotHealthService,
    private chatbotErrorService: ChatbotErrorService,
    private chatbotUserService: ChatbotUserService,
    private userService: UserService,
    private chatbotUserCreateService: ChatbotUserCreateService,
    private keycloakService: KeycloakService,
    private chatbotChatEventService: ChatbotChatEventService,
    private cartService: CartService,
    private router: Router,
    private translatorService: TranslatorService
  ) {
  }

  ngOnInit(): void {
    this.checkChatbotHealth();
    this.startChatbotHealthInterval();
    this.connectServices();
    this.canShowMobileView();
    this.checkChatbotUserStatus();
  }

  checkChatbotHealth(): void {
    this.chatbotHealthService.check_health();
    this.chatbotHealthService.getChatbotAPIHealthStatus()
      .pipe(untilDestroyed(this))
      .subscribe(isChatbotAPIHealthy => this.apiIsHealthy = isChatbotAPIHealthy);
  }

  startChatbotHealthInterval(): void {
    const pollingInterval = 10000;
    interval(pollingInterval)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.apiIsHealthy){
          this.checkChatbotHealth();
        }
      });
  }

  connectServices(): void {
    this.chatbotErrorService.errorOccurred$.pipe(untilDestroyed(this))
      .subscribe(errorOccurred => this.errorOccurred = errorOccurred);
    this.chatbotErrorService.errorMessage$.pipe(untilDestroyed(this))
      .subscribe(errorMessage => this.errorMessage = errorMessage);
    this.chatbotChatEventService.chatCreated$.pipe(untilDestroyed(this), filter(value => !!value))
      .subscribe(chat => this.onChatSelected(chat));
  }

  canShowMobileView() {
    this.isMobile = window.screen.width <= chatBreakPoint;
  }

  checkChatbotUserStatus(): void {
    this.userService.getChatbotUserStatus().subscribe({
      next: (chatbotUserStatus: ChatbotUserStatus) => {
        this.currentUsersChatbotStatus = chatbotUserStatus;
        switch (chatbotUserStatus) {
          case ChatbotUserStatus.chatbot_not_tried_yet: {
            this.showStartFreeTrialLockScreen = true;
            this.translatorKeyLockedComponent = FREE_TRIAL_TRANSLATOR_KEY;
            break;
          }
          case ChatbotUserStatus.chatbot_test: {
            this.checkChatbotUserExists();
            break;
          }
          case ChatbotUserStatus.chatbot_customer:
            // no action necessary
            this.checkChatbotUserExists();
            break;
          case ChatbotUserStatus.chatbot_cancelled:
            this.checkChatbotUserExists();
            break;
          case ChatbotUserStatus.chatbot_expired: {
            this.showPurchaseAccessLockScreen = true;
            this.translatorKeyLockedComponent = FREE_TRIAL_EXPIRED_TRANSLATOR_KEY;
            break;
          }
          case ChatbotUserStatus.chatbot_invited: {
            this.checkChatbotUserExists();
            break;
          }
          default: {
            break;
          }
        }
      },
      error: error => console.error('Error at getChatbotUserStatus', error)
    });
  }

  checkChatbotUserExists(): void {
    this.chatbotUserService.checkChatbotUserExists().subscribe({
      next: chatbotUserExists => {
        if (!chatbotUserExists) {
          this.chatbotUserCreateService.createUser()
            .then(() => console.log('Chatbot User successfully created'))
            .catch(reason => console.error('Error creating Chatbot User', reason));
        }
      },
      error: error => console.error('Error at checkChatbotUserExists', error)
    });
  }

  onChatSelected(chat: ChatbotChat): void {
    this.selectedChat = chat;
    this.showMobileSidebar = false;
    this.chatObject = chat;
  }

  toggleSidebar() {
    this.showMobileSidebar = !this.showMobileSidebar;
  }

  messageSend() {
    this.chatbotChatEventService.emitMessageSend();
  }

  addChatToChatList(chat: ChatbotChat) {
    this.chatbotChatEventService.emitChatCreated(chat);
  }

  startFreeTrial(): void {
    this.navigationReason = 'IS_FREE_TRIAL';
    this.showStartFreeTrialLockScreen = false;

    this.userService.activateChatbotFreeTrial().subscribe({
      next: () => {
        // update Keycloak Token to get updated Attribute
        this.keycloakService.updateToken(-1).then(() => {
          // create Chatbot User
          this.chatbotUserCreateService.createUser()
            .then(value => {
              console.log('Chatbot User created', value);
              this.currentUsersChatbotStatus = ChatbotUserStatus.chatbot_test;
            })
            .catch(error => console.error('Error creating new Chatbot User', error));
        }).catch(error => console.error('Error updating token', error));
      }
    });
  }

  purchaseChatbot(): void {
    this.cartService.replaceContent([new CartUpdate(Product.ID37_CHATBOT, 1)])
      .subscribe(() => {
        this.navigationReason = 'IS_PURCHASE';
        this.navigate();
      });
  }

  navigate() {
    switch (this.navigationReason) {
      case 'IS_PURCHASE': {
        this.router.navigate(['order']);
        break;
      }
      case 'CANCEL': {
        this.handleCancelNavigation();
        break;
      }
      case 'IS_FREE_TRIAL': {
        // should stay at chatbot
        break;
      }
    }
  }

  handleCancelNavigation() {
    if (this.currentUsersChatbotStatus === ChatbotUserStatus.chatbot_expired) {
      this.userService.rejectChatbotPurchase();
    }
    else {
      this.router.navigate(['dashboard']);
    }
  }

  handleCancelSubscription() {
    this.navigationReason = 'CANCEL';
    this.navigate();
  }

  handleSubscribeToJay() {
    if (this.showStartFreeTrialLockScreen) {
      this.startFreeTrial();
    }
    else if (this.showPurchaseAccessLockScreen) {
      this.purchaseChatbot();
    }
  }

  canShowChatbotJay() {
    return this.currentUsersChatbotStatus !== ChatbotUserStatus.chatbot_expired
      && this.currentUsersChatbotStatus !== ChatbotUserStatus.chatbot_not_tried_yet
      && this.currentUsersChatbotStatus !== ChatbotUserStatus.chatbot_error;
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-chat.' + key);
  }

  ngOnDestroy() {
    this.chatbotChatEventService.emitChatUndefined();
  }

  protected readonly ChatbotUserStatus = ChatbotUserStatus;
}
