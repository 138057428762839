import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {BasicUser} from '../../user/basic-user';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {FriendshipFunctions} from '../state/friendship-functions';
import {Friendship} from '../state/friendship';
import {FriendshipQuery} from '../state/friendship.query';
import {UserSearchService} from '../../user/user-search.service';
import {CurrentUser} from '../../user/current-user.service';
import {TranslatorService} from '../../translation/translator.service';
import {EmailSettingsService} from '../../account/email-settings/state/email-settings.service';

@Component({
  selector: 'app-friend-search-input',
  templateUrl: './friend-search-input.component.html',
  styleUrls: ['./friend-search-input.component.scss']
})
export class FriendSearchInputComponent implements OnInit, OnDestroy {

  @Output()
    searching: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
    searchResult: EventEmitter<BasicUser[] | undefined> = new EventEmitter<BasicUser[] | undefined>();
  @Output()
    searchFriendsResult: EventEmitter<Friendship[]> = new EventEmitter<Friendship[]>();
  searchKey: string = '';
  searchMode: boolean;
  searchInputChanged: Subject<string> = new Subject<string>();
  searchInputChangedSubscription: Subscription;
  friendshipsList: Friendship[];
  networkingState: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private currentUser: CurrentUser,
              private friendshipQuery: FriendshipQuery,
              private emailSettingsService: EmailSettingsService,
              private userSearchService: UserSearchService,
              private translatorService: TranslatorService) {
  }

  ngOnInit() {
    this.friendshipQuery.selectFriendships()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((friendships: Friendship[]) => {
        this.friendshipsList = friendships;
      });

    this.emailSettingsService.getEmailSettings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(emailSettings => {
        this.networkingState = emailSettings.informAboutNetworking;
      });

    this.createSearchInputChangedSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.searchInputChangedSubscription.unsubscribe();
  }

  propagateSearchResult(searchResult: BasicUser[] | undefined): void {
    this.searchResult.emit(searchResult);
  }

  propagateFriendsSearchResult(searchResult: Friendship[]): void {
    this.searchFriendsResult.emit(searchResult);
  }

  abortSearch() {
    this.searchKey = '';
    this.searchMode = false;
    this.propagateSearchResult(undefined);
    this.searching.emit(false);
    this.createSearchInputChangedSubscription();
  }

  resetSearch() {
    this.searchKey = '';
  }

  __(key: string): string {
    return this.translatorService.translate('friendSearch.' + key);
  }

  ___(key: string): string {
    return this.translatorService.translate(key);
  }

  private createSearchInputChangedSubscription(): void {
    if (this.searchInputChangedSubscription) {
      this.searchInputChangedSubscription.unsubscribe();
    }
    this.searchInputChangedSubscription = this.searchInputChanged
      .pipe(
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe(newSearchKey => {
        this.searchKey = newSearchKey;
        this.search();
      });
  }

  private search(): void {
    const toSearch: string = this.processedSearchKey();
    if (this.searchKey.length >= 3 && toSearch.length > 0) {
      this.searchMode = true;
      this.propagateSearchResult(undefined);
      this.searching.emit(true);
      if (this.networkingState) {
        this.userSearchService.searchUsersUsingNetworking(toSearch)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((list: BasicUser[]) => {
            console.log(list);
            this.propagateSearchResult(list);
            this.propagateFriendsSearchResult(undefined);
          });
      }
      else {
        this.propagateSearchResult(undefined);
        this.searchInFriendList(toSearch);
      }
    }
    if (this.searchKey.length === 0) {
      this.abortSearch();
    }
  }

  private processedSearchKey(): string {
    const processed = this.searchKey.trim()
      .split(/\s/g)
      .filter(word => word.length > 1)
      .filter((word, index) => index < 5)
      .join('~');
    return processed;
  }

  private searchInFriendList(searchKey: string) {
    const toSearch: string = searchKey.replace('~', '|');
    const results = this.friendshipsList.filter(friendship =>
      !(FriendshipFunctions
        .getFriendFullName(friendship, this.currentUser.keycloakId)
        .toLowerCase()
        .search(toSearch) === -1)
    );
    this.propagateFriendsSearchResult(results);
    this.propagateSearchResult(undefined);
  }
}
