<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">
    <div class="my-customers-div-container">
      <h2>
        {{__('heading')}}
      </h2>

      <!-- Button that shows/hides the new customers component -->
      <div class="clr-row clr-justify-content-end">
        <button (click)="toggleNewCustomers()"
                class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 id37-btn-hover-animation add-customer-button">
          @if(!showNewCustomers){
            <img src="assets/icons/add_onButton.svg" alt="Add new customers">
            <span>{{__('addClients')}}</span>
          } @else {
            <img src="assets/icons/close_onButton.svg" alt="Close new customers">
            <span>{{__('close')}}</span>
          }
        </button>
      </div>

      <app-new-customers (sent)="onSent($event)"
                         [groups$]="groups$"
                         [isOpen]="showNewCustomers"></app-new-customers>

      <div class="clr-row">
        <div class="clr-col-6" id="existing-clients-second-header">
          <h3>
            {{__('existingClients')}} ({{totalCustomerCount}})
          </h3>
        </div>

        <!-- Customer search input and button -->
        <div class="clr-col search-input-container">
          <input [(ngModel)]="searchInput"
                 (ngModelChange)="searchCustomers()"
                 maxlength="100"
                 placeholder="{{__('search')}}">
          <button (click)="clearSearchInput()"
                  class="id37-btn id37-btn-sm id37-btn-secondary">
            <img alt="clear" src="assets/icons/x.svg"/>
          </button>
        </div>
      </div>
    </div>

    <clr-datagrid class="my-customers-datagrid" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">

      <!-- Customer datagrid column headers -->
      <clr-dg-column [clrDgField]="'name'" class="datagrid-column-header">{{ __('name') }}</clr-dg-column>
      <clr-dg-column [clrDgField]="'GROUP'" class="datagrid-column-header">{{ __('group') }}</clr-dg-column>
      <clr-dg-column [clrDgField]="'DATE'" class="datagrid-column-header">{{ __('date') }}</clr-dg-column>
      <clr-dg-column [clrDgField]="'PRODUCT'" class="datagrid-column-header">{{ __('variation') }}</clr-dg-column>
      <clr-dg-column [clrDgField]="'ACTION'" class="datagrid-column-header">{{ __('action') }}</clr-dg-column>

      <!-- Customer datagrid contents -->
      <clr-dg-row *ngFor="let customer of customers">
        <clr-dg-cell>
          @if (customer.hasFinishedTests()){
            <a (click)="openReport(customer.keycloakId, customer.getIdOfFirstFinishedTest())">
              {{ customer.firstName }} {{customer.lastName }}
            </a>
          } @else {
            {{ customer.firstName }} {{customer.lastName }}
          }
        </clr-dg-cell>
        <clr-dg-cell>
          @if (customer.groupName){
           {{ customer.groupName }}
          } @else {
            <div class="no-group-label">
              {{ __('group.no-group-label') }}
            </div>
          }
        </clr-dg-cell>
        <clr-dg-cell>{{ customer.creationDate | date }}</clr-dg-cell>
        <clr-dg-cell>{{ __(customer.getVariationOfFirstTest()) }}</clr-dg-cell>

        <!-- Customer datagrid row action buttons per customer -->
        <clr-dg-cell id="customer-action-container">
          <ng-container *rolesAllowed="['master', 'partner']">
            @switch (customer.getMyCustomersAction()){
              @case (myCustomersAction.NEW_TEST_INVITE){
                <button (click)="openVariationModal(customer)"
                        class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 action-button">
                  <clr-icon shape="plus-circle"></clr-icon>
                  <span>{{ __(customer.getMyCustomersAction()) }}</span>
                </button>
              }
              @case (myCustomersAction.NEW_INVITE){
                <button (click)="sendNewInvite(customer)"
                     class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 action-button">
                  <clr-icon shape="plus-circle"></clr-icon>
                  <span>{{ __(customer.getMyCustomersAction()) }}</span>
                </button>
              }
              @case (myCustomersAction.DONE){
                <button class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 id37-btn-disabled action-button inactive-button">
                  <clr-icon shape="success-standard"></clr-icon>
                  <span>{{ __(customer.getMyCustomersAction()) }}</span>
                </button>
              }
              @case (myCustomersAction.GRANT){
                <button (click)="grantTestAccess(customer)"
                        class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 action-button">
                  <clr-icon shape="angle-double"></clr-icon>
                  <span>{{ __(customer.getMyCustomersAction()) }}</span>
                </button>
              }
              @case (myCustomersAction.IN_PROGRESS){
                <button class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 id37-btn-disabled action-button inactive-button">
                  <clr-icon shape="hourglass"></clr-icon>
                  <span>{{ __(customer.getMyCustomersAction()) }}</span>
                </button>
              }
              @case (myCustomersAction.CHANGE_PRODUCT){
                <button (click)="openVariationModal(customer)"
                        class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 action-button">
                  <clr-icon shape="pencil"></clr-icon>
                  <span>{{ __(customer.getMyCustomersAction()) }}</span>
                </button>
              }
            }
          </ng-container>
          <ng-container>
            @if(customer.hasFinishedTests()){
              <button (click)="openReport(customer.keycloakId, customer.getIdOfFirstFinishedTest())"
                      class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 modal-button"
                      title="{{__('openProfile')}}">
                <clr-icon shape="user"></clr-icon>
              </button>
            }
          </ng-container>
          <ng-container>
            <button (click)="openGroupChangeModal(customer)"
                    class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 modal-button"
                    title="{{__('changeGroup')}}">
              <clr-icon shape="users"></clr-icon>
            </button>
          </ng-container>
          <ng-container *rolesAllowed="['master']">
            @if (!customer.hasStartedTests()){
              <button (click)="openDeleteModal(customer)"
                      class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 modal-button"
                      title="{{__('deleteCustomer')}}">
                <clr-icon shape="trash"></clr-icon>
              </button>
            }
          </ng-container>
        </clr-dg-cell>
      </clr-dg-row>

      <!-- Customer datagrid pagination footer -->
      <clr-dg-footer>
        <clr-dg-pagination id="pagination-container" #pagination [clrDgPageSize]="10" [clrDgTotalItems]="totalCustomerCount">
          <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
            <div id="customer-number-selection-label">
              {{__('customers')}}
            </div>
          </clr-dg-page-size>
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} {{__('of')}} {{ this.totalCustomerCount }} {{__('customers')}}
        </clr-dg-pagination>
      </clr-dg-footer>

    </clr-datagrid>
  </div>
</div>





<!-- Modals -->

<!-- GROUP CHANGE MODAL -->
<id37-modal [(modalOpen)]="showGroupChangeModal">
  <id37-modal-title>
    {{__('changeGroupModal.title')}}
  </id37-modal-title>
  <id37-modal-body>
    <div>
      {{__('changeGroupModal.description')}}
    </div>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="clr-form-group">
          <label for="new-client-group">{{__('changeGroupModal.label')}}</label>
          <input [formControl]="newGroup" id="new-client-group" type="text">
        </div>
      </div>
    </div>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="clr-form-group">
          <label for="new-client-group-for-all">{{__('changeGroupModal.labelall')}}</label>
          <app-toggle-switch (valueChange)="groupChangeForAll=$event"
                             [value]="groupChangeForAll"
                             id="new-client-group-for-all">
          </app-toggle-switch>
        </div>
      </div>
    </div>
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button (click)="changeGroupOfCustomer(customerToChangeGroup); closeGroupChangeModal()"
              class="id37-btn-outline"
              type="button">
        {{__('changeGroupModal.button.change')}}
      </button>
      <button (click)="closeGroupChangeModal()" class="id37-btn id37-btn-secondary"
              type="button">
        {{__('changeGroupModal.button.abort')}}
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>



<!-- DELETE MODAL -->
<id37-modal [(modalOpen)]="showDeleteModal" [modalSize]="'sm'">
  <id37-modal-title>
    {{__('deleteClient.areYouSure')}}
  </id37-modal-title>
  <id37-modal-body [align]="'left'">
    {{__('deleteClient.explanation')}}
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button (click)="deleteCustomer(customerToDelete); closeDeleteModal()" class="id37-btn id37-btn-danger"
              type="button">
        {{__('deleteClient.accept')}}
      </button>
      <button (click)="closeDeleteModal()" class="id37-btn id37-btn-secondary"
              type="button">
        {{__('deleteClient.abort')}}
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>



<!-- TEST VARIATION MODAL -->
<id37-modal [(modalOpen)]="showTestVariationModal">
  <id37-modal-title>{{__('changeProduct')}}</id37-modal-title>
  <id37-modal-body>
    <div class="clr-row clr-justify-content-center">
      <form [formGroup]="form">
        <div class="radio-buttons-container">
          <ng-container>
            <label class="id37-radio-label id37-text3" for="16">
              <input (change)="changeVariation($event)" [checked]="testVariationChecked=='test.variation.16'"
                     formControlName="testVariation" id="16"
                     name="testVariation"
                     type="radio" value="ID37/16">
              <span class="id37-radio-button"></span>
              <span>ID37/16</span>
            </label>

            <label class="id37-radio-label id37-text3" for="15">
              <input (change)="changeVariation($event)" [checked]="testVariationChecked=='test.variation.15'"
                     formControlName="testVariation" id="15"
                     name="testVariation"
                     type="radio" value="ID37/15">
              <span class="id37-radio-button"></span>
              <span>ID37/15</span>
            </label>

            <label class="id37-radio-label id37-text3" for="14">
              <input (change)="changeVariation($event)" [checked]="testVariationChecked=='test.variation.14'"
                     formControlName="testVariation" id="14"
                     name="testVariation"
                     type="radio" value="ID37/14">
              <span class="id37-radio-button"></span>
              <span>ID37/14</span>
            </label>

            <label class="id37-radio-label id37-text3" for="13">
              <input (change)="changeVariation($event)" [checked]="testVariationChecked=='test.variation.unspecified'"
                     formControlName="testVariation" id="13"
                     name="testVariation"
                     type="radio"
                     value="customer-chooses">
              <span class="id37-radio-button"></span>
              <span>{{__('customerChoose')}}</span>
            </label>
          </ng-container>
        </div>
        <button (click)="onVariationSelection()" type="button">
          {{__('changeProductModal.button.change')}}
        </button>
      </form>
    </div>
  </id37-modal-body>
</id37-modal>
